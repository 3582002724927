<template>
  <div class="ms_main_wrapper">
    <app-side-menu />
    <app-header />
    <Suspense>
      <template #default>
        <div class="ms_content_wrapper padder_top60">
          <app-categories :cat_id="cat_id" :cat_name="cat_name" />
        </div>
      </template>
      <template #fallback>
        <app-loader/>
      </template>
    </Suspense>
    <app-bottom-navbar/>
  </div>
</template>

<script>
import AppHeader from "../../components/Include/AppHeader.vue";
import AppSideMenu from "../../components/Include/AppSideMenu.vue";
// import AppCategories from '../../components/Pages/AppCategories.vue'
import { defineAsyncComponent, Suspense } from "vue";
import AppLoader from '../../components/Spinners/AppLoader.vue';
import AppBottomNavbar from '../../components/Include/AppBottomNavbar.vue';
const AppCategories = defineAsyncComponent(() =>
  import("../../components/Pages/AppCategories.vue")
);
export default {
  components: { AppSideMenu, AppHeader, AppCategories, Suspense, AppLoader, AppBottomNavbar },
  name: "Categories",
  props: {
    cat_id: String,
    cat_name: String,
  },
};
</script>

<style>
</style>